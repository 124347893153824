import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";

const Journal = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  useEffect(() => {
    setPrevLocation(location.state.data);
  }, [location]);
  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="TRANKHANG" prevLocation={prevLocation} />
      <div className="pb-10">

    <div className="p-6 bg-gray-100 text-gray-800">
      <h1 className="text-2xl font-bold mb-4">
        Hướng Dẫn Lắp Đặt Máy Lạnh Đúng Chuẩn Kỹ Thuật Điện Lạnh Cực Kì Đơn Giản
      </h1>
      <p className="mb-4">
        Điện Lạnh Trần Khang là một trong những đơn vị uy tín với dịch vụ vệ sinh, lắp đặt, bảo trì máy lạnh tại Đồng Nai. Dưới đây là những chia sẻ về cách lắp đặt điều hòa máy lạnh đúng chuẩn kỹ thuật.
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-3">
        1. Vị trí lắp đặt máy lạnh đúng chuẩn
      </h2>

      <h3 className="text-lg font-medium mt-4">Vị trí lắp dàn nóng (cục nóng) máy lạnh</h3>
      <ul className="list-disc list-inside mb-4">
        <li>Dàn nóng được lắp đặt ở môi trường ngoài nhưng không được lắp nơi có nhiều người qua lại hoặc trực tiếp xuống đất.</li>
        <li>Không được lắp dàn nóng ở vị trí mặt trời trực tiếp chiếu vào.</li>
        <li>Không lắp ở nơi có nhiều cây, khói bụi.</li>
        <li>Vị trí lắp đặt nên thông thoáng, thuận tiện cho việc bảo dưỡng vệ sinh máy.</li>
      </ul>

      <h3 className="text-lg font-medium mt-4">Vị trí lắp dàn lạnh (cục lạnh) máy lạnh</h3>
      <ul className="list-disc list-inside mb-4">
        <li>Máy lạnh lắp phải thật chắc chắn, không bị rung khi hoạt động, đảm bảo nhiệt độ tỏa ra đều.</li>
        <li>Nên lắp đặt ở vị trí dễ dàng tháo lắp, thuận tiện vệ sinh.</li>
        <li>Dàn lạnh phải được lắp đặt trong phòng kín.</li>
        <li>Phải đảm bảo vị trí phân phối hơi lạnh đều và không bị cản trở.</li>
      </ul>

      <h3 className="text-lg font-medium mt-4">Yêu cầu kỹ thuật khi lắp đặt máy lạnh tại nhà:</h3>
      <ul className="list-disc list-inside mb-4">
        <li>Dàn nóng cách tường ít nhất 30cm để không khí lưu thông tốt.</li>
        <li>Đảm bảo đường thoát nước thải không bị tắc nghẽn.</li>
        <li>Máy lạnh phải được đặt trên khung kệ vững chắc, liên kết tốt với tường hoặc nền.</li>
        <li>Chọn vị trí lắp đặt đảm bảo tính thẩm mỹ và thuận tiện cho việc vệ sinh, bảo dưỡng.</li>
      </ul>

      <h2 className="text-xl font-semibold mt-6 mb-3">
        2. Cách lắp đặt máy lạnh đúng chuẩn kỹ thuật
      </h2>
      <ol className="list-decimal list-inside mb-4">
        <li>
          <strong>Lắp đặt dàn nóng:</strong> Đảm bảo vị trí thông thoáng và khung kệ vững chắc.
        </li>
        <li>
          <strong>Lắp đặt dàn lạnh:</strong> Đặt dàn lạnh trong phòng kín, cố định chắc chắn.
        </li>
        <li>
          <strong>Nối ống:</strong> Kết nối ống dẫn gas và nước thải đúng cách, đảm bảo kín.
        </li>
        <li>
          <strong>Chạy thử máy lạnh:</strong> Kiểm tra máy hoạt động ổn định trước khi bàn giao.
        </li>
      </ol>

      <p className="mt-6">
        Hy vọng hướng dẫn trên sẽ giúp bạn lắp đặt máy lạnh đúng kỹ thuật, đảm bảo hiệu quả và độ bền lâu dài.
      </p>
    </div>
<br/>

        <Link to="/shop">
          <button className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300">
            Tiếp Tục Mua Hàng   
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Journal;
