import React from "react";
import { Link } from "react-router-dom";
import {
  saleImgOne,
  saleImgTwo,
  saleImgThree,
} from "../../../assets/images/index";
import Image from "../../designLayouts/Image";

const Sale = () => {
  return (
    <div className="py-20 flex flex-col md:flex-row items-center justify-between gap-4 lg:gap-10">
      <div className="w-full md:w-2/3 lg:w-1/2 h-full">
      <h2 className="text-2xl font-bold text-center text-blue-600 mb-6">
      LẮP ĐẶT ĐIỀU HÒA TRUNG TÂM VRV, ĐIỀU HÒA CÔNG NGHIỆP
      </h2>
      <p className="text-center text-gray-600 mb-8">
      Điều hòa dùng trong các tòa nhà với các mục đích sử dụng nhiều, Công suất lớn thường được trang bị ống đồng và vật tư tốt để đáp ứng đúng hiệu quả sử dụng trong thời gian lâu dài
    
      </p>
        <Link to="/shop">
          <Image className="h-full w-full object-cover img-vrv" imgSrc={saleImgOne} />
        </Link>
      </div>
      <div className="w-full md:w-2/3 lg:w-1/2 h-auto flex flex-col gap-4 lg:gap-10">
        <div className="h-80 w-full">
          <Link to="/shop">
            <Image className="h-full w-full object-cover" imgSrc={saleImgTwo} />
          </Link>
        </div>
        <div className="h-80 w-full">
          <Link to="/shop">
            <Image
              className="h-full w-full object-cover"
              imgSrc={saleImgThree}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Sale;
