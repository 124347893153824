import {
  spfOne,
  spfTwo,
  spfThree,
  spfFour,
  bestSellerOne,
  bestSellerTwo,
  bestSellerThree,
  bestSellerFour,
  newArrOne,
  newArrTwo,
  newArrThree,
  newArrFour,
  saleImgOne,
  saleImgTwo,
  productsMl1,
  productsMl2,
  productsMl3,
  productsMl4,
  productsMl5,
  productsMl6,
  productsMl7,
  productsMl8,
  productsMl9,
  productsMl10,
  productsMl11,
  productsMl12, 
  productsMl13,
  productsMl14,
  productsMl15,
  productsMl16,
  productsMl17,
  productsMl18,
  productsMl19,
  productsMl20,
  productsMl21,
  productsMl22,
  productsMl23,
  panasonic,
} from "../assets/images/index";

// =================== NavBarList Start here ====================
export const navBarList = [
  {
    _id: 1001,
    title: "TRANG CHỦ",
    link: "/",
  },
  {
    _id: 1002,
    title: "GIỚI THIỆU",
    link: "/about",
  },
  {
    _id: 1003,
    title: "SẢN PHẨM",
    link: "/shop",
  },
  {
    _id: 1004,
    title: "DỊCH VỤ",
    link: "contact",
  },
  {
    _id: 1005,
    title: "TIN TỨC",
    link: "/",
  },
  {
    _id: 1006,
    title: "LIỆN HỆ",
    link: "/trankhang",
  },
];
// =================== NavBarList End here ======================
// =================== Special Offer data Start here ============
export const SplOfferData = [
  {
    _id: "201",
    img: productsMl1,
    productName: "May lạnh Inverter (2022)",
    price: "35.00",
    color: "XPU9XKH-8",
    badge: true,
    des: "Công suất 1.0 HP (9.000 BTU): Phù hợp với các phòng có diện tích nhỏ từ 10-15 m² như phòng ngủ, phòng làm việc nhỏ. Công nghệ Inverter: Giúp máy hoạt động êm ái, duy trì nhiệt độ ổn định và tiết kiệm điện năng đáng kể. Thiết kế hiện đại: Máy có thiết kế tinh tế với màu trắng sang trọng, dễ dàng phối hợp với nhiều kiểu nội thất khác nhau. Làm lạnh nhanh: Được trang bị công nghệ Powerful Mode giúp làm lạnh nhanh chóng ngay khi khởi động, đem lại cảm giác mát lạnh tức thì. Công nghệ kháng khuẩn và lọc không khí: Máy lạnh Daikin FTKB25XVMV tích hợp màng lọc bụi mịn PM2.5, giúp lọc sạch không khí, loại bỏ các tác nhân gây dị ứng và vi khuẩn, bảo vệ sức khỏe cho gia đình. Chế độ Eco: Tự động điều chỉnh công suất làm lạnh để tiết kiệm năng lượng nhưng vẫn đảm bảo sự thoải mái. Máy lạnh bền bỉ: Được thiết kế với khả năng chống ăn mòn tốt, phù hợp với điều kiện thời tiết khắc nghiệt. Hoạt động êm ái: Độ ồn thấp giúp mang lại không gian yên tĩnh, thoải mái cho người dùng. Máy lạnh Daikin Inverter FTKB25XVMV là sự lựa chọn lý tưởng cho những ai cần một thiết bị điều hòa không khí hiệu quả, tiết kiệm và hiện đại trong không gian sống.",
  },

];
// =================== Special Offer data End here ==============

// =================== PaginationItems Start here ===============

export const paginationItems = [
  {
    "_id": "1",
    "img": productsMl1,
    "badge": "MONO",
    "productName": "MÁY LẠNH PANASONIC",
    "color": "N9ZKH-8 ",
    "des": "1,0 HP",
    " price ": " 7,550,000 ",
    " xs ": " Malaysia ",
    "bh": "1 năm linh kiện- 7 năm máy nén"
},
{
    "_id": "2",
    "img": productsMl1,
    "badge": "MONO",
    "productName": "MÁY LẠNH PANASONIC",
    "color": "N12ZKH-8 ",
    "des": "1,5 HP",
    " price ": " 9,600,000 ",
    " xs ": " Malaysia ",
    "bh": "1 năm linh kiện- 7 năm máy nén"
},
{
    "_id": "3",
    "img": productsMl1,
    "badge": "MONO",
    "productName": "MÁY LẠNH PANASONIC",
    "color": "N18ZKH-8",
    "des": "2.0 HP",
    " price ": " 14,500,000 ",
    " xs ": " Malaysia ",
    "bh": "1 năm linh kiện- 7 năm máy nén"
},
{
    "_id": "4",
    "img": productsMl1,
    "badge": "MONO",
    "productName": "MÁY LẠNH PANASONIC",
    "color": "N24ZKH-8",
    "des": "2.5 HP",
    " price ": " 20,750,000 ",
    " xs ": " Malaysia ",
    "bh": "1 năm linh kiện- 7 năm máy nén"
},
{
    "_id": "5",
    "img": productsMl1,
    "badge": "Loại Inverter (2022) ",
    "productName": "MÁY LẠNH PANASONIC",
    "color": "XPU9XKH-8",
    "des": "1,0 HP",
    " price ": " 9,150,000 ",
    " xs ": " Malaysia ",
    "bh": "1 năm linh kiện- 7 năm máy nén"
},
{
    "_id": "6",
    "img": productsMl1,
    "badge": "Loại Inverter (2022) ",
    "productName": "MÁY LẠNH PANASONIC",
    "color": "XPU12XKH-8",
    "des": "1,5 HP",
    " price ": " 11,150,000 ",
    " xs ": " Malaysia ",
    "bh": "1 năm linh kiện- 7 năm máy nén"
},
{
    "_id": "7",
    "img": productsMl1,
    "badge": "Loại Inverter (2022) ",
    "productName": "MÁY LẠNH PANASONIC",
    "color": "XPU18XKH-8",
    "des": "2,0 HP",
    " price ": " 17,600,000 ",
    " xs ": " Malaysia ",
    "bh": "1 năm linh kiện- 7 năm máy nén"
},
{
    "_id": "8",
    "img": productsMl1,
    "badge": "Loại Inverter (2022) ",
    "productName": "MÁY LẠNH PANASONIC",
    "color": "XPU24XKH-8",
    "des": "2.5 HP",
    " price ": " Cập nhật ",
    " xs ": " Malaysia ",
    "bh": "1 năm linh kiện- 7 năm máy nén"
},
{
    "_id": "9",
    "img": productsMl1,
    "badge": "Inverter cao cấp có wife,đảo 4 chiều  ( 2023 ) ",
    "productName": "MÁY LẠNH PANASONIC",
    "color": "XU9ZKH-8 ",
    "des": "1,0 HP",
    " price ": " 11,450,000 ",
    " xs ": " Malaysia ",
    "bh": "1 năm linh kiện- 7 năm máy nén"
},
{
    "_id": "10",
    "img": productsMl1,
    "badge": "Inverter cao cấp có wife,đảo 4 chiều  ( 2023 ) ",
    "productName": "MÁY LẠNH PANASONIC",
    "color": "XU12ZKH-8 ",
    "des": "1,5 HP",
    " price ": " 14,200,000 ",
    " xs ": " Malaysia ",
    "bh": "1 năm linh kiện- 7 năm máy nén"
},
{
    "_id": "11",
    "img": productsMl1,
    "badge": "Inverter cao cấp có wife,đảo 4 chiều  ( 2023 ) ",
    "productName": "MÁY LẠNH PANASONIC",
    "color": "XU18ZKH-8",
    "des": "2,0 HP",
    " price ": " 21,600,000 ",
    " xs ": " Malaysia ",
    "bh": "1 năm linh kiện- 7 năm máy nén"
},
{
    "_id": "12",
    "img": productsMl1,
    "badge": "Inverter cao cấp có wife,đảo 4 chiều  ( 2023 ) ",
    "productName": "MÁY LẠNH PANASONIC",
    "color": "XU24ZKH-8",
    "des": "2,5 HP",
    " price ": " Cập nhật ",
    " xs ": " Malaysia ",
    "bh": "1 năm linh kiện- 7 năm máy nén"
},
{
    "_id": "13",
    "img": productsMl1,
    "badge": "Inverter",
    "productName": "MÁY LẠNH PANASONIC",
    "color": "U9ZKH",
    "des": "1,0 HP",
    " price ": " 10,450,000 ",
    " xs ": " Malaysia ",
    "bh": "1 năm linh kiện- 7 năm máy nén"
},
{
    "_id": "14",
    "img": productsMl1,
    "badge": "Inverter",
    "productName": "MÁY LẠNH PANASONIC",
    "color": "U12ZKH",
    "des": "1,5 HP",
    " price ": " 12,800,000 ",
    " xs ": " Malaysia ",
    "bh": "1 năm linh kiện- 7 năm máy nén"
},
{
    "_id": "15",
    "img": productsMl1,
    "badge": "Inverter 2024",
    "productName": "MÁY LẠNH PANASONIC",
    "color": "CU/CS-RU9AKH-8",
    "des": "1,0 HP",
    " price ": " 9,150,000 ",
    " xs ": " Malaysia ",
    "bh": "1 năm linh kiện- 7 năm máy nén"
},
{
    "_id": "16",
    "img": productsMl1,
    "badge": "Inverter 2024",
    "productName": "MÁY LẠNH PANASONIC",
    "color": "CU/CS-RU12AKH-8",
    "des": "1,5 HP",
    " price ": " 11,150,000 ",
    " xs ": " Malaysia ",
    "bh": "1 năm linh kiện- 7 năm máy nén"
},
{
    "_id": "1",
    "img": productsMl1,
    "badge": "Inverter",
    "productName": "MÁY LẠNH  CASPER",
    "color": "GC-09IS35",
    "des": "1,0 HP",
    " price ": " 4,950,000 ",
    " xs ": " Thái Lan ",
    "bh": "3 năm linh kiện- 12 năm máy nén"
},
{
    "_id": "2",
    "img": productsMl1,
    "badge": "Inverter",
    "productName": "MÁY LẠNH  CASPER",
    "color": "GC-12IS35",
    "des": "1,5 HP",
    " price ": " 5,950,000 ",
    " xs ": " Thái Lan ",
    "bh": "3 năm linh kiện- 12 năm máy nén"
},
{
    "_id": "3",
    "img": productsMl1,
    "badge": "Inverter",
    "productName": "MÁY LẠNH  CASPER",
    "color": "GC-18IS33",
    "des": "2,0 HP",
    " price ": " 10,750,000 ",
    " xs ": " Thái Lan ",
    "bh": "3 năm linh kiện- 12 năm máy nén"
},
{
    "_id": "4",
    "img": productsMl1,
    "badge": "Inverter",
    "productName": "MÁY LẠNH  CASPER",
    "color": "GC-24IS32",
    "des": "2,5 HP",
    " price ": " 13,700,000 ",
    " xs ": " Thái Lan ",
    "bh": "3 năm linh kiện- 12 năm máy nén"
},
{
    "_id": "5",
    "img": productsMl1,
    "badge": "Inverter 2024",
    "productName": "MÁY LẠNH  CASPER",
    "color": "TC-09IS36",
    "des": "1,0 HP",
    " price ": " 4,750,000 ",
    " xs ": " Thái Lan ",
    "bh": "3 năm linh kiện- 12 năm máy nén"
},
{
    "_id": "6",
    "img": productsMl1,
    "badge": "Inverter 2024",
    "productName": "MÁY LẠNH  CASPER",
    "color": "TC-12IS36",
    "des": "1,5 HP",
    " price ": " 5,700,000 ",
    " xs ": " Thái Lan ",
    "bh": "3 năm linh kiện- 12 năm máy nén"
},
{
    "_id": "1",
    "img": productsMl1,
    "badge": "INVERTER 2024",
    "productName": "MÁY LẠNH LG",
    "color": "V10WIN1",
    "des": "1,0 HP",
    " price ": " 6,850,000 ",
    " xs ": " Thái Lan ",
    "bh": "2 năm linh kiện- 10 năm máy nén"
},
{
    "_id": "2",
    "img": productsMl1,
    "badge": "INVERTER 2024",
    "productName": "MÁY LẠNH LG",
    "color": "V13WIN1",
    "des": "1,5 HP",
    " price ": " 7,850,000 ",
    " xs ": " Thái Lan ",
    "bh": "2 năm linh kiện- 10 năm máy nén"
},
{
    "_id": "3",
    "img": productsMl1,
    "badge": "INVERTER 2024",
    "productName": "MÁY LẠNH LG",
    "color": "V18WIN1",
    "des": "2,0 HP",
    " price ": " 12,600,000 ",
    " xs ": " Thái Lan ",
    "bh": "2 năm linh kiện- 10 năm máy nén"
},
{
    "_id": "4",
    "img": productsMl1,
    "badge": "INVERTER 2024",
    "productName": "MÁY LẠNH LG",
    "color": "V24WIN1",
    "des": "2,5 HP",
    " price ": " 15,700,000 ",
    " xs ": " Thái Lan ",
    "bh": "2 năm linh kiện- 10 năm máy nén"
},
{
    "_id": "1",
    "img": productsMl1,
    "badge": "INVERTER 2024",
    "productName": "MÁY LẠNH TOSHIBA                                           ",
    "color": "H10S4KCV2G-V",
    "des": "1.0HP",
    " price ": " 7,600,000 ",
    " xs ": " Thái Lan ",
    "bh": "2 năm toàn bộ máy"
},
{
    "_id": "2",
    "img": productsMl1,
    "badge": "INVERTER 2024",
    "productName": "MÁY LẠNH TOSHIBA                                           ",
    "color": "H13S4KCV2G-V",
    "des": "1.5HP",
    " price ": " 9,700,000 ",
    " xs ": " Thái Lan ",
    "bh": "2 năm toàn bộ máy"
},
{
    "_id": "3",
    "img": productsMl1,
    "badge": "INVERTER 2024",
    "productName": "MÁY LẠNH TOSHIBA",
    "color": "H18S4KCV2G-V",
    "des": "2.0HP",
    " price ": " 14,700,000 ",
    " xs ": " Thái Lan ",
    "bh": "2 năm toàn bộ máy"
},
{
    "_id": "4",
    "img": productsMl1,
    "badge": "INVERTER ĐẦU LỚN",
    "productName": "MÁY LẠNH TOSHIBA",
    "color": "H10E2KCVG ",
    "des": "1.0HP",
    " price ": " 8,950,000 ",
    " xs ": " Thái Lan ",
    "bh": "2 năm toàn bộ máy"
},
{
    "_id": "5",
    "img": productsMl1,
    "badge": "INVERTER ĐẦU LỚN",
    "productName": "MÁY LẠNH TOSHIBA",
    "color": "H13E2KCVG ",
    "des": "1.5HP",
    " price ": " 10,900,000 ",
    " xs ": " Thái Lan ",
    "bh": "2 năm toàn bộ máy"
},
{
    "_id": "6",
    "img": productsMl1,
    "badge": "INVERTER ĐẦU LỚN",
    "productName": "MÁY LẠNH TOSHIBA",
    "color": "H18E2KCVG ",
    "des": "2.0HP",
    " price ": " 15,950,000 ",
    " xs ": " Thái Lan ",
    "bh": "2 năm toàn bộ máy"
},
{
    "_id": "1",
    "img": productsMl1,
    "badge": "MONO",
    "productName": "MÁY LẠNH DAIKIN",
    "color": "FTF25XAV1V",
    "des": "1,0 HP",
    " price ": " 6,750,000 ",
    " xs ": "Việt Nam",
    "bh": "1 Năm linh kiện- 5 năm máy nén"
},
{
    "_id": "2",
    "img": productsMl1,
    "badge": "MONO",
    "productName": "MÁY LẠNH DAIKIN",
    "color": "FTF35XAV1V",
    "des": "1,5 HP",
    " price ": " 8,750,000 ",
    " xs ": "Việt Nam",
    "bh": "1 Năm linh kiện- 5 năm máy nén"
},
{
    "_id": "3",
    "img": productsMl1,
    "badge": "MONO",
    "productName": "MÁY LẠNH DAIKIN",
    "color": "FTF50XV1V",
    "des": "2,0 HP",
    " price ": " 13,900,000 ",
    " xs ": "Việt Nam",
    "bh": "1 Năm linh kiện- 5 năm máy nén"
},
{
    "_id": "4",
    "img": productsMl1,
    "badge": "Loại Inverter 2023",
    "productName": "MÁY LẠNH DAIKIN",
    "color": "FTKB25XVMV ",
    "des": "1,0 HP",
    " price ": " 7,900,000 ",
    " xs ": " Việt Nam ",
    "bh": "1 Năm linh kiện- 5 năm máy nén"
},
{
    "_id": "5",
    "img": productsMl1,
    "badge": "Loại Inverter 2023",
    "productName": "MÁY LẠNH DAIKIN",
    "color": "FTKB50XVMV",
    "des": "2,0 HP",
    " price ": " 16,100,000 ",
    " xs ": " Việt Nam ",
    "bh": "1 Năm linh kiện- 5 năm máy nén"
},
{
    "_id": "6",
    "img": productsMl1,
    "badge": "Loại Inverter 2023",
    "productName": "MÁY LẠNH DAIKIN",
    "color": "FTKB60XVMV",
    "des": "2.5 HP",
    " price ": " 21,700,000 ",
    " xs ": " Việt Nam ",
    "bh": "1 Năm linh kiện- 5 năm máy nén"
},
{
    "_id": "7",
    "img": productsMl1,
    "badge": "Loại Inverter 2024",
    "productName": "MÁY LẠNH DAIKIN",
    "color": "FTKB25YVMV ",
    "des": "1,0 HP",
    " price ": " 8,000,000 ",
    " xs ": " Việt Nam ",
    "bh": "1 Năm linh kiện- 5 năm máy nén"
},
{
    "_id": "8",
    "img": productsMl1,
    "badge": "Loại Inverter 2024",
    "productName": "MÁY LẠNH DAIKIN",
    "color": "FTKB35YVMV",
    "des": "1,5 HP",
    " price ": " 10,000,000 ",
    " xs ": " Việt Nam ",
    "bh": "1 Năm linh kiện- 5 năm máy nén"
},
{
    "_id": "9",
    "img": productsMl1,
    "badge": "Loại INVERTER\n(Có mắt thần)",
    "productName": "MÁY LẠNH DAIKIN",
    "color": "ATKC25UAVMV",
    "des": "1,0 HP",
    " price ": " 8,700,000 ",
    " xs ": "Việt Nam",
    "bh": "1 Năm linh kiện- 5 năm máy nén"
},
{
    "_id": "10",
    "img": productsMl1,
    "badge": "Loại INVERTER\n(Có mắt thần)",
    "productName": "MÁY LẠNH DAIKIN",
    "color": "ATKC35UAVMV",
    "des": "1,5 HP",
    " price ": " 10,500,000 ",
    " xs ": "Việt Nam",
    "bh": "1 Năm linh kiện- 5 năm máy nén"
},
{
    "_id": "11",
    "img": productsMl1,
    "badge": "Loại INVERTER\n(Có mắt thần)",
    "productName": "MÁY LẠNH DAIKIN",
    "color": "FTKC50UVMV",
    "des": "2.0 HP",
    " price ": " 16,900,000 ",
    " xs ": "Việt Nam",
    "bh": "1 Năm linh kiện- 5 năm máy nén"
},
{
    "_id": "12",
    "img": productsMl1,
    "badge": "Inverter",
    "productName": "MÁY LẠNH DAIKIN",
    "color": "FTKY25WAVMV",
    "des": "1.0 HP",
    " price ": " 10,400,000 ",
    " xs ": "Việt Nam",
    "bh": "1 Năm linh kiện- 5 năm máy nén"
},
{
    "_id": "13",
    "img": productsMl1,
    "badge": "Inverter",
    "productName": "MÁY LẠNH DAIKIN",
    "color": "FTKY35WAVMV",
    "des": "1,5 HP",
    " price ": " 13,100,000 ",
    " xs ": "Việt Nam",
    "bh": "1 Năm linh kiện- 5 năm máy nén"
},
{
    "_id": "14",
    "img": productsMl1,
    "badge": "Inverter",
    "productName": "MÁY LẠNH DAIKIN",
    "color": "FTKY50WVMV",
    "des": "2,0 HP",
    " price ": " 20,450,000 ",
    " xs ": "Việt Nam",
    "bh": "1 Năm linh kiện- 5 năm máy nén"
},
{
    "_id": "15",
    "img": productsMl1,
    "badge": "Loại Inverter Cao cấp",
    "productName": "MÁY LẠNH DAIKIN",
    "color": "FTKZ25VVMV",
    "des": "1,0 HP",
    " price ": " 13,900,000 ",
    " xs ": " Thái Lan ",
    "bh": "1 Năm linh kiện- 5 năm máy nén"
},
{
    "_id": "16",
    "img": productsMl1,
    "badge": "Loại Inverter Cao cấp",
    "productName": "MÁY LẠNH DAIKIN",
    "color": "FTKZ35VVMV",
    "des": "1,5 HP",
    " price ": " 17,000,000 ",
    " xs ": " Thái Lan ",
    "bh": "1 Năm linh kiện- 5 năm máy nén"
},
{
    "_id": "17",
    "img": productsMl1,
    "badge": "Loại Inverter Cao cấp",
    "productName": "MÁY LẠNH DAIKIN",
    "color": "FTKZ50VVMV",
    "des": "2,0 HP",
    " price ": " 26,600,000 ",
    " xs ": " Thái Lan ",
    "bh": "1 Năm linh kiện- 5 năm máy nén"
},
{
    "_id": "1",
    "img": productsMl1,
    "badge": "Loại MONO",
    "productName": "MÁY LẠNH AQUA ",
    "color": "AQA-KCR09PA",
    "des": "1.0HP",
    " price ": " 4,450,000 ",
    " xs ": "Thái Lan",
    "bh": "2 năm - máy ném ( 10 năm)"
},
{
    "_id": "2",
    "img": productsMl1,
    "badge": "Loại MONO",
    "productName": "MÁY LẠNH AQUA ",
    "color": "AQA-KCR12PA",
    "des": "1.5HP",
    " price ": " 5,450,000 ",
    " xs ": "Thái Lan",
    "bh": "2 năm - máy ném ( 10 năm)"
},
{
    "_id": "3",
    "img": productsMl1,
    "badge": "Loại MONO",
    "productName": "MÁY LẠNH AQUA ",
    "color": "AQA-KCR18PC",
    "des": "2.0HP",
    " price ": " 9,050,000 ",
    " xs ": "Thái Lan",
    "bh": "2 năm - máy ném ( 10 năm)"
},
{
    "_id": "4",
    "img": productsMl1,
    "badge": "Loại Inverter",
    "productName": "MÁY LẠNH AQUA ",
    "color": "AQA-RV10QC2",
    "des": "1,0 HP",
    " price ": " 5,450,000 ",
    " xs ": "Thái Lan",
    "bh": "2 năm - máy ném ( 10 năm)"
},
{
    "_id": "5",
    "img": productsMl1,
    "badge": "Loại Inverter",
    "productName": "MÁY LẠNH AQUA ",
    "color": "AQA-RV13QC2",
    "des": "1,5 HP",
    " price ": " 6,450,000 ",
    " xs ": "Thái Lan",
    "bh": "2 năm - máy ném ( 10 năm)"
},
{
    "_id": "6",
    "img": productsMl1,
    "badge": "Loại Inverter",
    "productName": "MÁY LẠNH AQUA ",
    "color": "AQA-RV18QA",
    "des": "2.0HP",
    " price ": " 10,400,000 ",
    " xs ": "Thái Lan",
    "bh": "2 năm - máy ném ( 10 năm)"
},
{
    "_id": "7",
    "img": productsMl1,
    "badge": "Loại Inverter",
    "productName": "MÁY LẠNH AQUA ",
    "color": "AQA-RV18QE",
    "des": "2.0HP",
    " price ": " 10,450,000 ",
    " xs ": "Thái Lan",
    "bh": "2 năm - máy ném ( 10 năm)"
},
{
    "_id": "8",
    "img": productsMl1,
    "badge": "Loại Inverter",
    "productName": "MÁY LẠNH AQUA ",
    "color": "AQA-RV24QA",
    "des": "2.5HP",
    " price ": " 14,200,000 ",
    " xs ": "Thái Lan",
    "bh": "2 năm - máy ném ( 10 năm)"
},
{
    "_id": "1",
    "img": productsMl1,
    "badge": "Inverter",
    "productName": "MÁY LẠNH COMFEE",
    "color": "CFS-10VAFF-V",
    "des": "1,0 HP",
    " price ": " 5,000,000 ",
    " xs ": "Thái Lan",
    "bh": "2 năm"
},
{
    "_id": "2",
    "img": productsMl1,
    "badge": "Inverter",
    "productName": "MÁY LẠNH COMFEE",
    "color": "CFS-13VAFF-V",
    "des": "1,5 HP",
    " price ": " 5,900,000 ",
    " xs ": "Thái Lan",
    "bh": "2 năm"
},
{
    "_id": "3",
    "img": productsMl1,
    "badge": "Inverter",
    "productName": "MÁY LẠNH COMFEE",
    "color": "CFS-18VAFF-V",
    "des": "2,0 HP",
    " price ": " 8,700,000 ",
    " xs ": "Thái Lan",
    "bh": "2 năm"
},
{
    "_id": "1",
    "img": productsMl1,
    "badge": "Inverter",
    "productName": "MÁY LẠNH SHARP",
    "color": "AH- X10ZEW ",
    "des": "1,0 HP",
    " price ": " 6,000,000 ",
    " xs ": " Thái Lan ",
    "bh": "2 năm máy - 10 năm máy nén"
},
{
    "_id": "2",
    "img": productsMl1,
    "badge": "Inverter",
    "productName": "MÁY LẠNH SHARP",
    "color": "AH-X13ZEW ",
    "des": "1,5 HP",
    " price ": " 7,000,000 ",
    " xs ": " Thái Lan ",
    "bh": "2 năm máy - 10 năm máy nén"
},
{
    "_id": "3",
    "img": productsMl1,
    "badge": "Inverter",
    "productName": "MÁY LẠNH SHARP",
    "color": "AH- X18ZEW",
    "des": "2,0 HP",
    " price ": " 10,400,000 ",
    " xs ": " Thái Lan ",
    "bh": "2 năm máy - 10 năm máy nén"
},
{
    "_id": "1",
    "img": productsMl1,
    "badge": "Inverter Winfee cao cấp",
    "productName": "MÁY LẠNH SAMSUNG",
    "color": "AR12TYHQ",
    "des": "1,5 HP",
    " price ": " 6,800,000 ",
    " xs ": "TQ/Việt Nam",
    "bh": "2 Năm - 10 Năm"
},
{
    "_id": "2",
    "img": productsMl1,
    "badge": "Inverter Winfee cao cấp",
    "productName": "MÁY LẠNH SAMSUNG",
    "color": "AR10DYHZAWKN/XSV",
    "des": "1,0 HP",
    " price ": " 6,950,000 ",
    " xs ": "Thái Lan",
    "bh": "2 Năm - 10 Năm"
},
{
    "_id": "3",
    "img": productsMl1,
    "badge": "Inverter Winfee cao cấp",
    "productName": "MÁY LẠNH SAMSUNG",
    "color": "AR13DYHZAWKN/XSV",
    "des": "1,5 HP",
    " price ": " 7,950,000 ",
    " xs ": "Thái Lan",
    "bh": "2 Năm - 10 Năm"
},
{
    "_id": "4",
    "img": productsMl1,
    "badge": "Inverter Winfee cao cấp",
    "productName": "MÁY LẠNH SAMSUNG",
    "color": "AR18CYFC",
    "des": "2,0 HP",
    " price ": " 11,650,000 ",
    " xs ": "Thái Lan",
    "bh": "2 Năm - 10 Năm"
},
{
    "_id": "1",
    "img": productsMl1,
    "badge": "Loại MONO",
    "productName": "Máy lạnh Mitsubishi-Electric",
    "color": "MSY/MUY-JS25VF",
    "des": "1,0 HP",
    " price ": " 7,050,000 ",
    " xs ": " Thái Lan ",
    "bh": "2 năm máy - 5 năm máy nén"
},
{
    "_id": "2",
    "img": productsMl1,
    "badge": "Loại MONO",
    "productName": "Máy lạnh Mitsubishi-Electric",
    "color": "MSY/MUY-JS25VF",
    "des": "1,5 HP",
    " price ": " 9,050,000 ",
    " xs ": " Thái Lan ",
    "bh": "2 năm máy - 5 năm máy nén"
},
{
    "_id": "3",
    "img": productsMl1,
    "badge": "Loại Inverter 2023",
    "productName": "Máy lạnh Mitsubishi-Electric",
    "color": "MSY/MUY-JW25VF",
    "des": "1,0 HP",
    " price ": " 8,550,000 ",
    " xs ": " Thái Lan ",
    "bh": "2 năm máy - 5 năm máy nén"
},
{
    "_id": "4",
    "img": productsMl1,
    "badge": "Loại Inverter 2023",
    "productName": "Máy lạnh Mitsubishi-Electric",
    "color": "MSY/MUY-JW35VF",
    "des": "1,5 HP",
    " price ": " 10,550,000 ",
    " xs ": " Thái Lan ",
    "bh": "2 năm máy - 5 năm máy nén"
},
{
    "_id": "5",
    "img": productsMl1,
    "badge": "Loại Inverter 2023",
    "productName": "Máy lạnh Mitsubishi-Electric",
    "color": "MSY/MUY-JW50VF",
    "des": "2,0 HP",
    " price ": " 16,600,000 ",
    " xs ": " Thái Lan ",
    "bh": "2 năm máy - 5 năm máy nén"
},
{
    "_id": "1",
    "img": productsMl1,
    "badge": "Loại MONO",
    "productName": "Máy lạnh Mitsubishi-Heavy",
    "color": "SRK/SRC 09CTR-S5",
    "des": "1.0 HP",
    " price ": " 6,500,000 ",
    " xs ": " Thái Lan ",
    "bh": "2 năm toàn bộ máy"
},
{
    "_id": "2",
    "img": productsMl1,
    "badge": "Loại MONO",
    "productName": "Máy lạnh Mitsubishi-Heavy",
    "color": "SRK/SRC 12CT-S5 ",
    "des": "1.5 HP",
    " price ": " 8,700,000 ",
    " xs ": " Thái Lan ",
    "bh": "2 năm toàn bộ máy"
},
{
    "_id": "3",
    "img": productsMl1,
    "badge": "Loại INVERTER",
    "productName": "Máy lạnh Mitsubishi-Heavy",
    "color": "SRK/SRC 10YYP-W5",
    "des": "1.0 HP",
    " price ": " 7,800,000 ",
    " xs ": " Thái Lan ",
    "bh": "2 năm toàn bộ máy"
},
{
    "_id": "4",
    "img": productsMl1,
    "badge": "Loại INVERTER",
    "productName": "Máy lạnh Mitsubishi-Heavy",
    "color": "SRK/SRC 13YYP-W5",
    "des": "1.5 HP",
    " price ": " 9,800,000 ",
    " xs ": " Thái Lan ",
    "bh": "2 năm toàn bộ máy"
},
{
    "_id": "5",
    "img": productsMl1,
    "badge": "Loại INVERTER",
    "productName": "Máy lạnh Mitsubishi-Heavy",
    "color": "SRK/SRC 18YXP-W5",
    "des": "2.0 HP",
    " price ": " 16,050,000 ",
    " xs ": " Thái Lan ",
    "bh": "2 năm toàn bộ máy"
},
{
    "_id": "1",
    "img": productsMl1,
    "badge": "MONO",
    "productName": "MÁY LẠNH RETEECH",
    "color": "RT/RC9",
    "des": "1.0 HP",
    " price ": " 4,350,000 ",
    " xs ": "Thái Lan",
    "bh": "2 năm máy - 5 năm máy nén"
},
{
    "_id": "2",
    "img": productsMl1,
    "badge": "MONO",
    "productName": "MÁY LẠNH RETEECH",
    "color": "RT/RC12",
    "des": "1.5 HP",
    " price ": " 5,200,000 ",
    " xs ": "Thái Lan",
    "bh": "2 năm máy - 5 năm máy nén"
},
{
    "_id": "3",
    "img": productsMl1,
    "badge": "MONO",
    "productName": "MÁY LẠNH RETEECH",
    "color": "RT/RC18",
    "des": "2.0HP",
    " price ": " 8,800,000 ",
    " xs ": "Thái Lan",
    "bh": "2 năm máy - 5 năm máy nén"
},
{
    "_id": "4",
    "img": productsMl1,
    "badge": "MONO",
    "productName": "MÁY LẠNH RETEECH",
    "color": "RT24/RC24",
    "des": "2.5HP",
    " price ": " 11,300,000 ",
    " xs ": "Thái Lan",
    "bh": "2 năm máy - 5 năm máy nén"
},
{
    "_id": "5",
    "img": productsMl1,
    "badge": "INVERTER",
    "productName": "MÁY LẠNH RETEECH",
    "color": "RTV/RCV9",
    "des": "1.0 HP",
    " price ": " 5,100,000 ",
    " xs ": "Indo/ Thái Lan",
    "bh": "2 năm máy - 5 năm máy nén"
},
{
    "_id": "6",
    "img": productsMl1,
    "badge": "INVERTER",
    "productName": "MÁY LẠNH RETEECH",
    "color": "RTV/RCV12",
    "des": "1.5 HP",
    " price ": " 6,100,000 ",
    " xs ": "Indo/ Thái Lan",
    "bh": "2 năm máy - 5 năm máy nén"
},
{
    "_id": "7",
    "img": productsMl1,
    "badge": "INVERTER",
    "productName": "MÁY LẠNH RETEECH",
    "color": "RTV/RCV18",
    "des": "2.0 HP",
    " price ": " 11,000,000 ",
    " xs ": "Indo/ Thái Lan",
    "bh": "2 năm máy - 5 năm máy nén"
},
{
    "_id": "8",
    "img": productsMl1,
    "badge": "INVERTER",
    "productName": "MÁY LẠNH RETEECH",
    "color": "RTV/RCV24",
    "des": "2.5 HP",
    " price ": " 129,000,000 ",
    " xs ": "Indo/ Thái Lan",
    "bh": "2 năm máy - 5 năm máy nén"
},
{
    "_id": "1",
    "img": productsMl1,
    "badge": "MONO",
    "productName": "MÁY LẠNH NAGAKAWA",
    "color": "NS-C09R2T30",
    "des": "1.0 HP",
    " price ": " 4,100,000 ",
    " xs ": "Malaysia",
    "bh": "2 năm máy - 10 năm máy nén"
},
{
    "_id": "2",
    "img": productsMl1,
    "badge": "MONO",
    "productName": "MÁY LẠNH NAGAKAWA",
    "color": "NS-C12R2T30",
    "des": "1.5 HP",
    " price ": " Tạm hết ",
    " xs ": "Malaysia",
    "bh": "2 năm máy - 10 năm máy nén"
},
{
    "_id": "3",
    "img": productsMl1,
    "badge": "MONO",
    "productName": "MÁY LẠNH NAGAKAWA",
    "color": "NS-C18R2T30",
    "des": "2.0HP",
    " price ": " 8,200,000 ",
    " xs ": "Malaysia",
    "bh": "2 năm máy - 10 năm máy nén"
},
{
    "_id": "4",
    "img": productsMl1,
    "badge": "INVERTER",
    "productName": "MÁY LẠNH NAGAKAWA",
    "color": "NIS-C09R2T29",
    "des": "1.0 HP",
    " price ": " 4,900,000 ",
    " xs ": "Malaysia",
    "bh": "2 năm máy - 10 năm máy nén"
},
{
    "_id": "5",
    "img": productsMl1,
    "badge": "INVERTER",
    "productName": "MÁY LẠNH NAGAKAWA",
    "color": "NIS-C12R2T29",
    "des": "1.5 HP",
    " price ": " 5,900,000 ",
    " xs ": "Malaysia",
    "bh": "2 năm máy - 10 năm máy nén"
},
{
    "_id": "6",
    "img": productsMl1,
    "badge": "INVERTER",
    "productName": "MÁY LẠNH NAGAKAWA",
    "color": "NIS-C18R2H10",
    "des": "2.0HP",
    " price ": " 9,300,000 ",
    " xs ": "Malaysia",
    "bh": "2 năm máy - 10 năm máy nén"
},
{
    "_id": "1",
    "img": productsMl1,
    "badge": "MONO",
    "productName": "Máy lạnh MIDEA",
    "color": "MSAFIII-10CRN8",
    "des": "1,0 HP",
    " price ": " 4,150,000 ",
    " xs ": "Việt Nam",
    "bh": "3 năm máy - 5 năm máy nén"
},
{
    "_id": "2",
    "img": productsMl1,
    "badge": "MONO",
    "productName": "Máy lạnh MIDEA",
    "color": "MSAFII-13CRN8",
    "des": "1,5 HP",
    " price ": " 5,150,000 ",
    " xs ": "Việt Nam",
    "bh": "3 năm máy - 5 năm máy nén"
},
{
    "_id": "3",
    "img": productsMl1,
    "badge": "MONO",
    "productName": "Máy lạnh MIDEA",
    "color": "MSAFII-18CRN8",
    "des": "2,0 HP",
    " price ": " 8,800,000 ",
    " xs ": "Việt Nam",
    "bh": "3 năm máy - 5 năm máy nén"
},
{
    "_id": "4",
    "img": productsMl1,
    "badge": " INVERTER 2023",
    "productName": "Máy lạnh MIDEA",
    "color": "MSAGII-10CRDN8",
    "des": "1,0 HP",
    " price ": " 4,950,000 ",
    " xs ": "Việt Nam",
    "bh": "3 năm máy - 5 năm máy nén"
},
{
    "_id": "5",
    "img": productsMl1,
    "badge": " INVERTER 2023",
    "productName": "Máy lạnh MIDEA",
    "color": "MSAGII-13CRDN8",
    "des": "1,5 HP",
    " price ": " 5,950,000 ",
    " xs ": "Việt Nam",
    "bh": "3 năm máy - 5 năm máy nén"
},
{
    "_id": "6",
    "img": productsMl1,
    "badge": " INVERTER 2023",
    "productName": "Máy lạnh MIDEA",
    "color": "MSAGII-18CRDN8",
    "des": "2,0 HP",
    " price ": " 9,700,000 ",
    " xs ": "Việt Nam",
    "bh": "3 năm máy - 5 năm máy nén"
},
{
    "_id": "7",
    "img": productsMl1,
    "badge": " INVERTER",
    "productName": "Máy lạnh MIDEA",
    "color": "MSAFB-24HRDN8",
    "des": "2,5 HP",
    " price ": " 12,850,000 ",
    " xs ": "Việt Nam",
    "bh": "3 năm máy - 5 năm máy nén"
},
{
    "_id": "1",
    "img": productsMl1,
    "badge": "MONO",
    "productName": "Máy lạnh AKINO",
    "color": "TH-TC09FSFA",
    "des": "1,0 HP",
    " price ": " 3,950,000 ",
    " xs ": " Thái Lan ",
    "bh": "2 năm máy - 10 năm máy nén"
},
{
    "_id": "2",
    "img": productsMl1,
    "badge": "MONO",
    "productName": "Máy lạnh AKINO",
    "color": "TH-TC12FSFA",
    "des": "1,5 HP",
    " price ": " 4,900,000 ",
    " xs ": " Thái Lan ",
    "bh": "2 năm máy - 10 năm máy nén"
},
{
    "_id": "3",
    "img": productsMl1,
    "badge": "MONO",
    "productName": "Máy lạnh AKINO",
    "color": "TH-TC18FSFA",
    "des": "2,0 HP",
    " price ": " 8,000,000 ",
    " xs ": " Thái Lan ",
    "bh": "2 năm máy - 10 năm máy nén"
},
{
    "_id": "4",
    "img": productsMl1,
    "badge": "MONO GAZ 32",
    "productName": "Máy lạnh AKINO",
    "color": "TH-TC24FSFA",
    "des": "2,5 HP",
    " price ": " 11,000,000 ",
    " xs ": " Thái Lan ",
    "bh": "2 năm máy - 10 năm máy nén"
},
{
    "_id": "5",
    "img": productsMl1,
    "badge": "INVERTER GAZ410",
    "productName": "Máy lạnh AKINO",
    "color": "AKN-12CINV1FA",
    "des": "1,5 HP",
    " price ": " 5,750,000 ",
    " xs ": " Thái Lan ",
    "bh": "2 năm máy - 10 năm máy nén"
},
{
    "_id": "6",
    "img": productsMl1,
    "badge": "INVERTER GAZ 32",
    "productName": "Máy lạnh AKINO",
    "color": "AKN-9INV2FA",
    "des": "1,0 HP",
    " price ": " 4,800,000 ",
    " xs ": " Thái Lan ",
    "bh": "2 năm máy - 10 năm máy nén"
},
{
    "_id": "7",
    "img": productsMl1,
    "badge": "INVERTER GAZ 32",
    "productName": "Máy lạnh AKINO",
    "color": "AKN-12INV2FA",
    "des": "1,5 HP",
    " price ": " 5,800,000 ",
    " xs ": " Thái Lan ",
    "bh": "2 năm máy - 10 năm máy nén"
},
{
    "_id": "8",
    "img": productsMl1,
    "badge": "INVERTER GAZ 32",
    "productName": "Máy lạnh AKINO",
    "color": "AKN-18INV2FA",
    "des": "2,0 HP",
    " price ": " 10,000,000 ",
    " xs ": " Thái Lan ",
    "bh": "2 năm máy - 10 năm máy nén"
},
{
    "_id": "9",
    "img": productsMl1,
    "badge": "INVERTER GAZ 32",
    "productName": "Máy lạnh AKINO",
    "color": " AKN-24INV2FA",
    "des": "2,5 HP",
    " price ": " 12,500,000 ",
    " xs ": " Thái Lan ",
    "bh": "2 năm máy - 10 năm máy nén"
},
{
    "_id": "1",
    "img": productsMl1,
    "badge": "MONO GAZ 32",
    "productName": "Máy lạnh GREE",
    "color": "AMORE9CN",
    "des": "1,0 HP",
    " price ": " 5,700,000 ",
    " xs ": " Trung Quốc  ",
    "bh": "3 năm máy - 5 năm máy nén"
},
{
    "_id": "2",
    "img": productsMl1,
    "badge": "MONO GAZ 32",
    "productName": "Máy lạnh GREE",
    "color": "AMORE12CN",
    "des": "1,5 HP",
    " price ": " 7,600,000 ",
    " xs ": " Trung Quốc  ",
    "bh": "3 năm máy - 5 năm máy nén"
},
{
    "_id": "3",
    "img": productsMl1,
    "badge": "MONO GAZ 32",
    "productName": "Máy lạnh GREE",
    "color": "AMORE18CN",
    "des": "2,0 HP",
    " price ": " 11,700,000 ",
    " xs ": " Trung Quốc  ",
    "bh": "3 năm máy - 5 năm máy nén"
},
{
    "_id": "4",
    "img": productsMl1,
    "badge": "MONO GAZ 32",
    "productName": "Máy lạnh GREE",
    "color": "AMORE24CN",
    "des": "2,5 HP",
    " price ": " 17,700,000 ",
    " xs ": " Trung Quốc  ",
    "bh": "3 năm máy - 5 năm máy nén"
},
{
    "_id": "5",
    "img": productsMl1,
    "badge": "PULAR INVERTER GAZ 32",
    "productName": "Máy lạnh GREE",
    "color": "GWC18PC-K3D0P4",
    "des": "2,0 HP",
    " price ": " 13,700,000 ",
    " xs ": " Trung Quốc  ",
    "bh": "3 năm máy - 5 năm máy nén"
},
{
    "_id": "6",
    "img": productsMl1,
    "badge": "FAIRY INVERTER GAZ 32",
    "productName": "Máy lạnh GREE",
    "color": "GWC09FB-K6D9A1W",
    "des": "1,0 HP",
    " price ": " 6,650,000 ",
    " xs ": " Trung Quốc  ",
    "bh": "3 năm máy - 5 năm máy nén"
},
{
    "_id": "7",
    "img": productsMl1,
    "badge": "FAIRY INVERTER GAZ 32",
    "productName": "Máy lạnh GREE",
    "color": "GWC12FB-K6D9A1W",
    "des": "1,5 HP",
    " price ": " 8,200,000 ",
    " xs ": " Trung Quốc  ",
    "bh": "3 năm máy - 5 năm máy nén"
},
{
    "_id": "8",
    "img": productsMl1,
    "badge": "FAIRY INVERTER GAZ 32",
    "productName": "Máy lạnh GREE",
    "color": "GWC18FB-K6D9A1W",
    "des": "2,0 HP",
    " price ": " 14,200,000 ",
    " xs ": " Trung Quốc  ",
    "bh": "3 năm máy - 5 năm máy nén"
},
{
    "_id": "9",
    "img": productsMl1,
    "badge": "CHAEM INVERTER GAZ 32",
    "productName": "Máy lạnh GREE",
    "color": "CHARM09CL",
    "des": "1,0 HP",
    " price ": " 6,500,000 ",
    " xs ": " Trung Quốc  ",
    "bh": "3 năm máy - 5 năm máy nén"
},
{
    "_id": "10",
    "img": productsMl1,
    "badge": "CHAEM INVERTER GAZ 32",
    "productName": "Máy lạnh GREE",
    "color": "CHARM12CL",
    "des": "1,5 HP",
    " price ": " 8,100,000 ",
    " xs ": " Trung Quốc  ",
    "bh": "3 năm máy - 5 năm máy nén"
}
];
// =================== PaginationItems End here =================
