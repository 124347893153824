import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";

const About = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  useEffect(() => {
    setPrevLocation(location.state.data);
  }, [location]);
  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="Giới Thiệu" prevLocation={prevLocation} />
      <div className="pb-10">

    <div className="p-6 bg-gray-100">
      {/* Tiêu đề */}
      <h1 className="text-3xl font-bold text-center text-blue-600 mb-6">
        Đại lý bán máy lạnh dịch vụ điện lạnh chính hãng - Giá sỉ tại kho
      </h1>

      {/* Thông tin giới thiệu */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">
          1. Chúng tôi cung cấp các dòng máy lạnh chính hãng
        </h2>
        <p className="text-gray-600 mb-4">
          Là đại lý phân phối của các nhà sản xuất hàng đầu, chúng tôi cung cấp
          các dòng máy lạnh từ Panasonic, Daikin, Toshiba, Mitsubishi Heavy,
          Sharp, Mitsubishi Electric, Aqua, LG, Samsung, Reetech, Nagakawa,
          Sumikura, Funiki, Midea…
        </p>
        <p className="text-gray-600">
          Với lợi thế nhập hàng giá gốc và chính sách ưu đãi từ nhà sản xuất,
          chúng tôi cam kết mang đến giá cả cạnh tranh nhất trên thị trường.
        </p>
      </section>

      {/* Danh mục sản phẩm */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">
          2. Các dòng sản phẩm chúng tôi cung cấp
        </h2>
        <ul className="list-disc list-inside space-y-2 text-gray-600">
          <li>Máy lạnh treo tường</li>
          <li>Máy lạnh âm trần</li>
          <li>Máy lạnh áp trần</li>
          <li>Máy lạnh tủ đứng</li>
          <li>Máy lạnh nối ống gió</li>
          <li>Hệ thống máy lạnh VRV</li>
        </ul>
      </section>

      {/* Dịch vụ kỹ thuật */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">
          3. Dịch vụ kỹ thuật chuyên nghiệp
        </h2>
        <ul className="list-disc list-inside space-y-2 text-gray-600">
          <li>Thi công lắp đặt, di dời, sửa chữa các dòng máy lạnh.</li>
          <li>
            Lắp đặt hệ thống máy lạnh cho căn hộ cao cấp, chung cư, biệt thự,
            nhà hàng, trung tâm hội nghị.
          </li>
          <li>
            Đội ngũ kỹ thuật viên lành nghề, tư vấn giải pháp tối ưu và tiết
            kiệm.
          </li>
        </ul>
      </section>

      {/* Sứ mệnh */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">
          4. Sứ mệnh của Máy lạnh giá sỉ
        </h2>
        <p className="text-gray-600">
          Mang đến cho khách hàng chất lượng sản phẩm và dịch vụ tốt nhất trên
          thị trường.
        </p>
      </section>

      {/* Lý do khách hàng chọn chúng tôi */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">
          5. Vì sao chọn Máy lạnh giá sỉ?
        </h2>
        <ul className="list-disc list-inside space-y-2 text-gray-600">
          <li>
            <strong>Chất lượng dịch vụ:</strong> Đội ngũ kỹ thuật viên tay nghề
            cao, sản phẩm chính hãng.
          </li>
          <li>
            <strong>Sự an tâm:</strong> Sản phẩm được chăm chút tỉ mỉ từ nhà sản
            xuất đến tay khách hàng.
          </li>
          <li>
            <strong>Giá trị:</strong> Mang đến giải pháp tốt nhất và tối ưu nhất
            cho khách hàng.
          </li>
        </ul>
      </section>

      {/* Kêu gọi hành động */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">
          6. Liên hệ với chúng tôi
        </h2>
        <p className="text-gray-600">
          Nếu bạn đồng cảm với giá trị mà Máy lạnh giá sỉ hướng đến, hãy liên hệ
          với chúng tôi để được tư vấn và hỗ trợ tốt nhất.
        </p>
      </section>
    </div>

<br/>
        <Link to="/shop">
          <button className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300">
            Tiếp Tục Mua Hàng
          </button>
        </Link>
      </div>
    </div>
  );
};

export default About;
