import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";

const Journal = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  useEffect(() => {
    setPrevLocation(location.state.data);
  }, [location]);
  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="TRANKHANG" prevLocation={prevLocation} />
      <div className="pb-10">

    <div className="p-6 bg-gray-100 text-gray-800">
      <h1 className="text-2xl font-bold mb-4">
        Bảng giá dịch vụ vệ sinh máy lạnh tại Trần Khang
      </h1>

      <p className="mb-6">
        Máy lạnh là thiết bị không thể thiếu trong các gia đình hiện đại. Để máy lạnh hoạt động hiệu quả, cần vệ sinh định kỳ. Điện Lạnh Trần Khang cung cấp dịch vụ chuyên nghiệp, nhanh chóng, chỉ sau 30 phút kỹ thuật viên sẽ có mặt.
      </p>

      <h2 className="text-xl font-semibold mb-3">Bảng giá dịch vụ</h2>
      <table className="table-auto border-collapse w-full mb-6">
        <thead>
          <tr className="bg-gray-200">
            <th className="border px-4 py-2">Hạng mục công việc</th>
            <th className="border px-4 py-2">Đơn vị tính</th>
            <th className="border px-4 py-2">Số lượng máy</th>
            <th className="border px-4 py-2">Đơn giá (VND)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border px-4 py-2">Vệ sinh máy lạnh treo tường 1.0 – 1.5 HP</td>
            <td className="border px-4 py-2">Bộ</td>
            <td className="border px-4 py-2">1 – 10</td>
            <td className="border px-4 py-2">150,000</td>
          </tr>
          <tr>
            <td className="border px-4 py-2">Máy tủ đứng, âm trần, áp trần 2 HP – 2.5 HP</td>
            <td className="border px-4 py-2">Bộ</td>
            <td className="border px-4 py-2">1 – 5</td>
            <td className="border px-4 py-2">200,000</td>
          </tr>
          <tr>
            <td className="border px-4 py-2">Máy tủ đứng, âm trần, áp trần 4 HP – 5.5 HP</td>
            <td className="border px-4 py-2">Bộ</td>
            <td className="border px-4 py-2">1 – 5</td>
            <td className="border px-4 py-2">300,000 – 400,000</td>
          </tr>
        </tbody>
      </table>

      <h2 className="text-xl font-semibold mb-3">Lợi ích khi vệ sinh máy lạnh</h2>
      <ul className="list-disc list-inside mb-6">
        <li>Loại bỏ bụi bẩn, cải thiện chất lượng không khí.</li>
        <li>Tăng hiệu quả làm lạnh, giảm tiêu thụ điện năng.</li>
        <li>Ngăn ngừa hỏng hóc và tăng tuổi thọ thiết bị.</li>
        <li>Giảm thiểu nguy cơ ảnh hưởng sức khỏe.</li>
      </ul>

      <h2 className="text-xl font-semibold mb-3">Những câu hỏi thường gặp</h2>
      <div className="mb-6">
        <h3 className="font-medium">Có nên tự vệ sinh máy lạnh tại nhà không?</h3>
        <p>
          Nếu bạn có dụng cụ và biết cách tháo lắp khoa học, bạn có thể tự vệ sinh. Tuy nhiên, nếu không có kinh nghiệm, nên sử dụng dịch vụ chuyên nghiệp để tránh hư hỏng.
        </p>
      </div>
      <div className="mb-6">
        <h3 className="font-medium">Khi nào cần vệ sinh máy lạnh?</h3>
        <p>
          - Sử dụng trong gia đình: vệ sinh từ 3 – 4 tháng/lần.
          <br />
          - Công ty, nhà hàng: 3 tháng/lần hoặc 1 – 2 tháng/lần nếu môi trường nhiều bụi bẩn.
          <br />
          - Xí nghiệp sản xuất: 1 tháng/lần.
        </p>
      </div>
      <div className="mb-6">
        <h3 className="font-medium">Tại sao máy lạnh thường xuyên chảy nước?</h3>
        <p>
          Nguyên nhân phổ biến bao gồm: ống thoát nước không đúng cách, lưới lọc bẩn, quạt hỏng hoặc thiếu gas. Để khắc phục, cần liên hệ dịch vụ chuyên nghiệp.
        </p>
      </div>

      <h2 className="text-xl font-semibold mb-3">Liên hệ với chúng tôi</h2>
      <p>
        Điện Lạnh Trần Khang làm việc từ Thứ 2 đến Chủ Nhật, kể cả ngày lễ. Liên hệ qua số <strong>0937329036</strong> để đặt lịch.
      </p>
    </div>

<br/>
        <Link to="/shop">
          <button className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300">
            Tiếp Tục Mua Hàng   
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Journal;
