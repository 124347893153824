import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  createRoutesFromElements,
  Route,
  ScrollRestoration,
} from "react-router-dom";
import Footer from "./components/home/Footer/Footer";
import FooterBottom from "./components/home/Footer/FooterBottom";
import Header from "./components/home/Header/Header";
import HeaderBottom from "./components/home/Header/HeaderBottom";
import SpecialCase from "./components/SpecialCase/SpecialCase";
import About from "./pages/About/About";
import SignIn from "./pages/Account/SignIn";
import SignUp from "./pages/Account/SignUp";
import Cart from "./pages/Cart/Cart";
import Contact from "./pages/Contact/Contact";
import Home from "./pages/Home/Home";
import Journal from "./pages/Journal/Journal";

import Baotrimaylanh from "./pages/Journal/baotrimaylanh";
import Lapdatmaylanh from "./pages/Journal/lapdatmaylanh";
import Vesinhmaylanh from "./pages/Journal/vesinhmaylanh";
import Suachuanaylanh from "./pages/Journal/suachuanaylanh";
import Vesinhmaygiat from "./pages/Journal/vesinhmaygiat";


import Offer from "./pages/Offer/Offer";
import Payment from "./pages/payment/Payment";
import ProductDetails from "./pages/ProductDetails/ProductDetails";
import Shop from "./pages/Shop/Shop";

const Layout = () => {
  return (
    <div>
      <Header />
      <HeaderBottom />
      <SpecialCase />
      <ScrollRestoration />
      <Outlet />
      <Footer />
      <FooterBottom />
    </div>
  );
};
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Layout />}>
        {/* ==================== Header Navlink Start here =================== */}
        <Route index element={<Home />}></Route>
        <Route path="/shop" element={<Shop />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/trankhang" element={<Journal />}></Route>

        <Route path="/baotrimaylanh" element={<Baotrimaylanh />}></Route>
        <Route path="/lapdatmaylanh" element={<Lapdatmaylanh />}></Route>
        <Route path="/vesinhmaylanh" element={<Vesinhmaylanh />}></Route>
        <Route path="/suachuanaylanh" element={<Suachuanaylanh />}></Route>
        <Route path="/vesinhmaygiat" element={<Vesinhmaygiat />}></Route>
        {/* ==================== Header Navlink End here ===================== */}
        <Route path="/offer" element={<Offer />}></Route>
        <Route path="/product/:_id" element={<ProductDetails />}></Route>
        <Route path="/cart" element={<Cart />}></Route>
        <Route path="/paymentgateway" element={<Payment />}></Route>
      </Route>
      <Route path="/signup" element={<SignUp />}></Route>
      <Route path="/signin" element={<SignIn />}></Route>
    </Route>
  )
);



const FloatingIcons = () => {
  const icons = [
    {
      id: 1,
      color: "bg-red-500",
      icon: "https://classic.vn/wp-content/uploads/2022/07/phone-icon.png", // Đường dẫn hình ảnh cho "Call"
      tooltip: "Call",
      href: "tel:0362483250",
    },
    {
      id: 2,
      color: "bg-blue-500",
      icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/91/Icon_of_Zalo.svg/2048px-Icon_of_Zalo.svg.png", // Đường dẫn hình ảnh logo Zalo
      tooltip: "Zalo",
      href: "https://zalo.me/123456789",
    },
    {
      id: 3,
      color: "bg-blue-700",
      icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/be/Facebook_Messenger_logo_2020.svg/1024px-Facebook_Messenger_logo_2020.svg.png", // Đường dẫn hình ảnh logo Messenger
      tooltip: "Messenger",
      href: "https://www.facebook.com/share/19jRvo8nkM/",
    },
    {
      id: 4,
      color: "bg-green-500",
      icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/39/Google_Maps_icon_%282015-2020%29.svg/1200px-Google_Maps_icon_%282015-2020%29.svg.png", // Đường dẫn hình ảnh cho Map
      tooltip: "Map",
      href: "https://maps.app.goo.gl/27whehWmCJaTnTxY8",
    },
  ];

  return (
    <div className="fixed right-4 top-2/3 flex flex-col items-center space-y-4">
      {icons.map(({ id, color, icon, tooltip, href }) => (
        <a href={href} key={id} target="_blank">
        <button
          key={id}
          className={`${color} w-12 h-12 rounded-full flex items-center justify-center shadow-lg hover:scale-110 transition-transform`}
          title={tooltip}
        >
          <img src={icon} alt={tooltip} className="w-6 h-6" />
        </button>
        </a>
      ))}
    </div>
    
  );
};





function App() {
  return (
    <div className="font-bodyFont">
      <RouterProvider router={router} />
      <FloatingIcons />
    </div>
  );
}

export default App;
