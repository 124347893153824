import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";

const Journal = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  useEffect(() => {
    setPrevLocation(location.state.data);
  }, [location]);
  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="TRANKHANG" prevLocation={prevLocation} />
      <div className="pb-10">

    <div className="p-6 bg-gray-100">
      <h1 className="text-3xl font-bold text-center text-blue-600 mb-6">
        Dịch vụ vệ sinh máy giặt - Điện lạnh Trần Khang
      </h1>

      {/* Dịch vụ cung cấp */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">
          1. Dịch vụ Trần Khang cung cấp
        </h2>
        <ul className="list-disc list-inside space-y-2 text-gray-600">
          <li>Vệ sinh máy lạnh, tủ lạnh, máy giặt, lò vi sóng, máy nước nóng.</li>
          <li>Vệ sinh máy giặt: cửa trên, cửa ngang, lồng đôi.</li>
          <li>Lắp đặt máy giặt, thi công đường ống cấp/thoát nước máy giặt.</li>
        </ul>
      </section>

      {/* Khi nào nên vệ sinh */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">
          2. Khi nào nên vệ sinh máy giặt?
        </h2>
        <p className="text-gray-600 mb-2">
          <span className="font-bold text-red-500">Thời gian khuyến nghị:</span>
        </p>
        <ul className="list-disc list-inside space-y-2 text-gray-600">
          <li><strong>Máy giặt cửa trên:</strong> 6 tháng/lần.</li>
          <li><strong>Máy giặt cửa trước:</strong> 12 tháng/lần.</li>
        </ul>
        <p className="mt-2">
          Nguyên nhân: Tránh tích tụ cặn bột giặt gây mùi hôi và ảnh hưởng linh kiện.
        </p>
      </section>

      {/* Quy trình */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">
          3. Quy trình vệ sinh máy giặt
        </h2>
        <ol className="list-decimal list-inside space-y-2 text-gray-600">
          <li>Tiếp nhận thông tin qua Tổng đài, Zalo, Facebook, hoặc đặt lịch trực tuyến.</li>
          <li>Báo giá theo bảng giá quy định.</li>
          <li>Kiểm tra tình trạng hoạt động trước khi làm việc.</li>
          <li>Tháo rời lồng giặt và vệ sinh sạch sẽ.</li>
          <li>Kiểm tra, bàn giao máy và viết phiếu thanh toán.</li>
          <li>Bảo hành với thời gian rõ ràng theo hạng mục.</li>
        </ol>
      </section>

      {/* Lý do lựa chọn */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">
          4. Lý do khách hàng chọn Trần Khang
        </h2>
        <ul className="list-disc list-inside space-y-2 text-gray-600">
          <li><strong>Hoàn phí 100%</strong> nếu dịch vụ không đạt yêu cầu.</li>
          <li>Đội ngũ thợ chuyên nghiệp, tay nghề cao.</li>
          <li>Có mặt nhanh chóng tại nội thành.</li>
          <li>Chính sách bảo hành và hậu mãi chu đáo.</li>
        </ul>
      </section>

      {/* Thương hiệu */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">
          5. Các thương hiệu máy giặt thường vệ sinh
        </h2>
        <ul className="list-disc list-inside space-y-2 text-gray-600">
          <li>Samsung, Toshiba, Electrolux, LG, Panasonic, Sanyo, Midea...</li>
        </ul>
      </section>

      {/* FAQ */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">
          6. Câu hỏi thường gặp (FAQ)
        </h2>
        <div className="bg-yellow-100 border-l-4 border-yellow-500 p-4 mb-4">
          <strong>6.1. Vệ sinh máy giặt mất bao lâu?</strong>
          <p>Lồng đứng: Khoảng 60 phút. Lồng ngang: Khoảng 90 phút.</p>
        </div>
        <div className="bg-yellow-100 border-l-4 border-yellow-500 p-4 mb-4">
          <strong>6.2. Vệ sinh bằng gì?</strong>
          <p>Sử dụng vòi xịt áp lực, không dùng hóa chất gây ảnh hưởng sức khỏe.</p>
        </div>
        <div className="bg-yellow-100 border-l-4 border-yellow-500 p-4 mb-4">
          <strong>6.3. Thời gian thợ có mặt?</strong>
          <p>Sau khi tiếp nhận thông tin, kỹ thuật viên sẽ được điều phối ngay.</p>
        </div>
        <div className="bg-yellow-100 border-l-4 border-yellow-500 p-4">
          <strong>6.4. Không sạch có mất phí không?</strong>
          <p>Hoàn phí 100% nếu khách không hài lòng hoặc dịch vụ không đạt yêu cầu.</p>
        </div>
      </section>
    </div>
<br/>

        <Link to="/shop">
          <button className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300">
            Tiếp Tục Mua Hàng   
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Journal;
