import React from "react";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../redux/orebiSlice";

const ProductInfo = ({ productInfo }) => {
  const features = [
    "Công suất phù hợp với phòng có diện tích từ 9m² - 15m²",
    "Công nghệ PAM Inverter tiết kiệm điện hiệu quả, vận hành êm",
    "Gồm 4 chế độ gió, đảm bảo đáp ứng đa dạng nhu cầu làm mát",
    "Công nghệ làm lạnh nhanh cho cảm giác mát lạnh ngay tức thì",
    "Công nghệ lớp phủ kép ngăn ngừa tích tụ bụi bẩn và nấm mốc",
    "Hệ thống trao đổi nhiệt máy lạnh phủ lớp Bluefin chống ăn mòn",
    "Hộp kim loại bảo vệ bo mạch hạn chế cháy nổ, bảo đảm an toàn",
    "Vô cùng tiện lợi khi có tính năng Tự khởi động lại khi có điện",
  ];

  const dispatch = useDispatch();
  return (
    <div className="flex flex-col gap-5">
      <h2 className="text-3xl">{productInfo.productName}</h2>
      <p className="text-xl font-semibold">Liên Hệ</p>
      <p className="text-xl font-semibold">{productInfo.badge}</p>
      <p className="text-xl font-semibold">Công suất: {productInfo.des}</p>
      
      <div className="bg-gray-50 p-6 rounded-lg shadow-md max-w-2xl mx-auto">
      <h1 className="text-xl font-bold text-gray-800 mb-4">
        Đặc điểm nổi bật
      </h1>
      <ul className="list-disc list-inside space-y-2">
        {features.map((feature, index) => (
          <li key={index} className="text-gray-700">
            {feature}
          </li>
        ))}
      </ul>
    </div>
      <p className="text-base text-gray-600"></p>
      <p className="text-sm">
      </p>
      <p className="font-medium text-lg">
        <span className="font-normal">Mã:</span> {productInfo.color}
      </p>
      <button
        onClick={() =>
          dispatch(
            addToCart({
              _id: productInfo.id,
              name: productInfo.productName,
              quantity: 1,
              image: productInfo.img,
              badge: productInfo.badge,
              price: productInfo.price,
              colors: productInfo.color,
              cs: productInfo.cs
            })
          )
        }
        className="w-full py-4 bg-primeColor hover:bg-black duration-300 text-white text-lg font-titleFont"
      >
        Thêm vào giỏ hàng
      </button>
      <button
        onClick={() => window.open('tel: 0362483250', "_blank")}
        className="w-full py-4 bg-red-500 hover:bg-red-600 duration-300 text-white text-lg font-titleFont"
      >
        Liên Hệ
      </button>
      <p className="font-normal text-sm">
        <span className="text-base font-medium"> Categories:</span> LG, Samsung, Xiaomi, Huawei
      </p>
    </div>
  );
};

export default ProductInfo;
