import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";

const Journal = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  useEffect(() => {
    setPrevLocation(location.state.data);
  }, [location]);
  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="TRANKHANG" prevLocation={prevLocation} />
      <div className="pb-10">


    <div className="p-6 bg-gray-100 text-gray-800">
      <h1 className="text-2xl font-bold mb-4">
        Cách bảo trì, bảo dưỡng máy lạnh tại nhà bền lâu và một số lưu ý
      </h1>
      <p className="mb-4">
        Bảo trì máy lạnh là bước quan trọng giúp cho điều hòa hoạt động ổn định
        và hiệu quả, đặc biệt vào những ngày trời nắng nóng kéo dài. Hãy cùng
        Cleanipedia tìm hiểu cách bảo trì, bảo dưỡng điều hòa tại nhà bền lâu
        cực đơn giản qua bài viết sau.
      </p>
      <p className="font-bold text-blue-700 mb-4">
        CÔNG TY TNHH THƯƠNG MẠI DỊCH VỤ KỸ THUẬT CƠ ĐIỆN LẠNH TRẦN KHANG
      </p>

      <h2 className="text-xl font-semibold mt-6 mb-3">
        Vì sao nên bảo dưỡng, bảo trì máy lạnh thường xuyên?
      </h2>
      <ul className="list-disc list-inside mb-4">
        <li>Giúp cho không khí được trong lành</li>
        <li>
          Tăng tuổi thọ: Máy lạnh được lau chùi thường xuyên sẽ hoạt động tốt
          nhất với năng suất cao.
        </li>
        <li>Tiết kiệm điện năng</li>
      </ul>

      <h2 className="text-xl font-semibold mt-6 mb-3">
        Chuẩn bị dụng cụ bảo dưỡng, bảo trì máy lạnh
      </h2>
      <ul className="list-disc list-inside mb-4">
        <li>Máy hút bụi (nếu có)</li>
        <li>Tuốc nơ vít</li>
        <li>Bơm tăng áp và vòi nước áp suất cao</li>
        <li>Bình dạng xịt có dung dịch tẩy rửa</li>
        <li>Khăn sạch hoặc giẻ lau</li>
        <li>Túi ni lông hoặc áo mưa tiện lợi</li>
      </ul>

      <h2 className="text-xl font-semibold mt-6 mb-3">
        Hướng dẫn các bước bảo dưỡng, bảo trì máy lạnh
      </h2>
      <div className="space-y-3">
        <p>
          <strong>Bước 1:</strong> Tiến hành ngắt nguồn điện. Tắt điều hòa và
          ngắt toàn bộ nguồn điện, đợi 2 phút trước khi bắt đầu.
        </p>
        <p>
          <strong>Bước 2:</strong> Kiểm tra lượng gas trong điều hòa. Đảm bảo
          không bị rò rỉ và thay gas nếu cần.
        </p>
        <p>
          <strong>Bước 3:</strong> Kiểm tra hoạt động. Mở vỏ máy kiểm tra linh
          kiện và thay thế nếu có vấn đề.
        </p>
        <p>
          <strong>Bước 4:</strong> Vệ sinh dàn lạnh. Sử dụng dung dịch tẩy rửa
          để làm sạch bụi bẩn.
        </p>
        <p>
          <strong>Bước 5:</strong> Vệ sinh cánh quạt. Làm sạch bụi bẩn bám trên
          cánh quạt bằng dung dịch chuyên dụng.
        </p>
        <p>
          <strong>Bước 6:</strong> Vệ sinh dàn nóng. Dùng bơm áp lực để làm sạch
          bụi bẩn và côn trùng bám.
        </p>
        <p>
          <strong>Bước 7:</strong> Vệ sinh lưới lọc. Tháo bộ lọc khí, rửa nước
          ấm và để ráo trước khi lắp lại.
        </p>
        <p>
          <strong>Bước 8:</strong> Kiểm tra lại. Kiểm tra dây điện, nguồn điện,
          và bật điều hòa xem có hoạt động ổn định không.
        </p>
      </div>

      <h2 className="text-xl font-semibold mt-6 mb-3">
        Những lưu ý bảo trì, bảo dưỡng máy lạnh
      </h2>
      <ul className="list-disc list-inside mb-4">
        <li>Tắt máy lạnh khi không sử dụng.</li>
        <li>Theo dõi âm thanh từ máy lạnh.</li>
        <li>Bảo dưỡng máy lạnh định kỳ.</li>
      </ul>

      <p className="mt-6">
        Hy vọng hướng dẫn trên sẽ giúp bạn bảo trì máy lạnh một cách hiệu quả và
        bền lâu nhất.
      </p>
    </div>

<br/>
        
        <Link to="/shop">
          <button className="w-52 h-10 bg-primeColor text-white hover:bg-black duration-300">
            Tiếp Tục Mua Hàng   
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Journal;
