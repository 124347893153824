import React from "react";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import {
  spfOne,
  spfTwo,
  spfThree,
  spfFour,
  newArrTwo,
  productsMl1,
  productsMl2,
  productsMl3,
  productsMl4,
  productsMl5,
  productsMl6,
  productsMl7,
  productsMl8,
  productsMl9,
  productsMl10,
  productsMl11,
  productsMl12,
  productsMl13,
  productsMl14,
  productsMl15,
  productsMl16,
  productsMl17,
  productsMl18,
  productsMl19,
  productsMl21,
  productsMl22,
  productsMl23,
} from "../../../assets/images/index";

const SpecialOffers = () => {
  return (
    <div className="w-full pb-20">
      <Heading heading="Sản Phẩm Giảm Giá" />
      <div className="w-full grid grid-cols-2 md:grid-cols-2 lgl:grid-cols-3 xl:grid-cols-4 gap-10">
      <Product
            _id="100001"
            img={productsMl1}
            productName="Máy lạnh GREE"
            badge="MONO GAZ 32"
            color='AMORE18CN'
            des="2,0 HP"
          />
           <Product
            _id="100001"
            img={productsMl1}
            productName="Máy lạnh GREE"
            badge="MONO GAZ 32"
            color='AMORE18CN'
            des="2,0 HP"
          />
           <Product
            _id="100001"
            img={productsMl1}
            productName="Máy lạnh GREE"
            badge="MONO GAZ 32"
            color='AMORE18CN'
            des="2,0 HP"
          />
           <Product
            _id="100001"
            img={productsMl1}
            productName="Máy lạnh GREE"
            badge="MONO GAZ 32"
            color='AMORE18CN'
            des="2,0 HP"
          />
           <Product
            _id="100001"
            img={productsMl1}
            productName="Máy lạnh GREE"
            badge="MONO GAZ 32"
            color='AMORE18CN'
            des="2,0 HP"
          />
           <Product
            _id="100001"
            img={productsMl1}
            productName="Máy lạnh GREE"
            badge="MONO GAZ 32"
            color='AMORE18CN'
            des="2,0 HP"
          />
           <Product
            _id="100001"
            img={productsMl1}
            productName="Máy lạnh GREE"
            badge="MONO GAZ 32"
            color='AMORE18CN'
            des="2,0 HP"
          />
           <Product
            _id="100001"
            img={productsMl1}
            productName="Máy lạnh GREE"
            badge="MONO GAZ 32"
            color='AMORE18CN'
            des="2,0 HP"
          />
      </div>
    </div>
  );
};

export default SpecialOffers;
