import React, { useState } from "react";
import Banner from "../../components/Banner/Banner";
import BannerBottom from "../../components/Banner/BannerBottom";
import BestSellers from "../../components/home/BestSellers/BestSellers";
import NewArrivals from "../../components/home/NewArrivals/NewArrivals";
import Sale from "../../components/home/Sale/Sale";
import SpecialOffers from "../../components/home/SpecialOffers/SpecialOffers";
import YearProduct from "../../components/home/YearProduct/YearProduct";
import Slider from "react-slick";
import {
  handlerOne,
  handlerTwo,
  handlerThree,
  handlerFour,
  handlerFive,
  handlerSix,
  misubishi,
  toshiba,
  samsung,
  panasonic,
  lg,

} from "../../assets/images/index";
import Image from "../../components/designLayouts/Image";
import { m } from "framer-motion";




const ServiceCard = ({ icon, title }) => {
  return (
    <div className="flex flex-col items-center p-4">
      <div className="bg-blue-100 p-4 rounded-full mb-4">{icon}</div>
      <h3 className="text-lg font-semibold">{title}</h3>
    </div>
  );
};


const Services = () => {
  const [dotActive, setDocActive] = useState(0);
  return (
    <div className="py-8 ">
      <h2 className="text-2xl font-bold text-center text-blue-600 mb-6">
        DỊCH VỤ CỦA CHÚNG TÔI
      </h2>
      <p className="text-center text-gray-600 mb-8">
        Bạn có nhu cầu sửa chữa, vệ sinh, lắp đặt, bảo trì máy lạnh với chi phí phải chăng với chất lượng tốt nhất, hãy liên hệ Cơ Điện Lạnh Trần Khang.
      </p>
      <div className="w-full bg-white">
    </div>
      
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 text-center">
        <ServiceCard
          icon={<img src="https://dienlanhthanhhai.com/upload/news/bao-tri-may-lanh-2999.png" width={50} height={50} alt="LẮP ĐẶT MÁY LẠNH" />}
          title="LẮP ĐẶT MÁY LẠNH"
        />
        <ServiceCard
          icon={<img src="https://dienlanhthanhhai.com/upload/news/ve-sinh-may-lanh-3549.png" width={50} height={50} alt="LẮP ĐẶT MÁY LẠNH" />}
          title="VỆ SINH MÁY LẠNH"
        />
        <ServiceCard
         icon={<img src="https://dienlanhthanhhai.com/upload/news/map-dat-may-lanh-5101.png" width={50} height={50} alt="LẮP ĐẶT MÁY LẠNH" />}
          title="SỬA CHỮA MÁY LẠNH TẠI NHÀ"
        />
        <ServiceCard
          icon={<img src="https://dienlanhthanhhai.com/upload/news/ve-sinh-may-lanh-3549-3022.png" width={50} height={50} alt="LẮP ĐẶT MÁY LẠNH" />}
          title="VỆ SINH MÁY GIẶT"
        />
      </div>
    </div>
  );
};



const Home = () => {
  return (
    <div className="w-full mx-auto">
      <Banner />
         {/*  <BannerBottom /> */}
      <div className="max-w-container mx-auto px-4">
        <Services />
        <Sale />
        <Servicesbaner />
       
        <WarrantyPolicy />
        <NewArrivals />
        <BestSellers />
        <RepairProcess />
        <YearProduct />
         <SpecialOffers /> 
        <Partners />
      </div>
    </div>
  );
};

export default Home;



const Partners = () => {
  return (
    <div className="flex flex-col items-center my-8">
      {/* Tiêu đề */}
      <div className="flex items-center space-x-2">
        <h2 className="text-xl font-bold text-blue-800">ĐỐI TÁC</h2>
        <div className="w-12 h-1 bg-red-600"></div>
      </div>

      {/* Danh sách logo */}
      <div className="flex flex-wrap justify-center items-center mt-4 space-x-6">
        <Image imgSrc={samsung} className="max-w-[100px]" />
        <Image imgSrc={misubishi} className="max-w-[100px]" />
        <Image imgSrc={lg} className="max-w-[100px]" />
        <Image imgSrc={panasonic} className="max-w-[100px]" />
        <Image imgSrc={toshiba} className="max-w-[100px]" />
        <Image imgSrc={samsung} className="max-w-[100px]" />
      </div>
    </div>
  );
};



const Servicesbaner = () => {
  return (
    <div className="flex flex-col md:flex-row justify-between items-start bg-gradient-to-r from-blue-900 to-blue-800 text-white p-8">
      {/* Left Section */}
      <div className="md:w-1/2">
        <h1 className="text-2xl font-bold mb-4">
          DỊCH VỤ LẮP ĐẶT – SỬA CHỮA – VỆ SINH – BẢO TRÌ
        </h1>
        <p className="mb-4">
          Cơ điện lạnh Trần Khang cung cấp thiết bị và dịch vụ sửa máy lạnh công nghiệp, dân dụng, VRV:
        </p>
        <ul className="space-y-3">
          <li className="flex items-start">
            <span className="text-yellow-400 mr-2">✔️</span> Vệ sinh bảo trì máy lạnh Công Nghiệp
          </li>
          <li className="flex items-start">
            <span className="text-yellow-400 mr-2">✔️</span> Cung cấp lắp đặt và sửa chữa hệ thống máy lạnh Công nghiệp VRV
          </li>
          <li className="flex items-start">
            <span className="text-yellow-400 mr-2">✔️</span> Vệ sinh bảo trì kho đông mát
          </li>
          <li className="flex items-start">
            <span className="text-yellow-400 mr-2">✔️</span> Thay dàn lạnh, vệ sinh dàn lạnh
          </li>
          <li className="flex items-start">
            <span className="text-yellow-400 mr-2">✔️</span> Vệ sinh và lắp đặt hệ điều hòa âm trần, treo tường
          </li>
          <li className="flex items-start">
            <span className="text-yellow-400 mr-2">✔️</span> Thi công ống đồng âm tường
          </li>
          <li className="flex items-start">
            <span className="text-yellow-400 mr-2">✔️</span> Thay block, xử lý xì ga, bơm ga máy lạnh, tủ lạnh
          </li>
          <li className="flex items-start">
            <span className="text-yellow-400 mr-2">✔️</span> Nhận thanh lý các mặt hàng đồ điện lạnh
          </li>
        </ul>
      </div>

      {/* Right Section */}
      <div className="md:w-1/2 flex flex-wrap justify-center gap-4 mt-8 md:mt-0">
        <div className="w-32 h-32 rounded-full bg-cover bg-center" style={{ backgroundImage: 'url("//dienlanhcanhlinh.com/wp-content/uploads/2022/06/slider-2.png")' }}></div>
        <div className="w-32 h-32 rounded-full bg-cover bg-center" style={{ backgroundImage: 'url("https://dienlanhtruongthinh.vn/wp-content/uploads/2017/03/banner-06.jpg")' }}></div>
        <div className="w-32 h-32 rounded-full bg-cover bg-center" style={{ backgroundImage: 'url("https://congcutot.vn/uploads/store/page/article/2023/10/nghe-dien-lanh-la-nghe-gi-dd.jpg")' }}></div>
      </div>
    </div>
  );
};



const StepItem = ({ stepNumber, title, description, icon }) => {
  return (
    <div className="flex mb-6">
      <div className="flex-shrink-0">
        <div className="text-center bg-blue-100 p-4 rounded-full mb-2">{icon}</div>
      </div>
      <div className="ml-4">
        <h3 className="text-lg font-bold text-blue-600">{`Bước ${stepNumber}: ${title}`}</h3>
        <p className="text-gray-600">{description}</p>
      </div>
    </div>
  );
};

const RepairProcess = () => {
  return (
    <div className="py-8">
      <h2 className="text-2xl font-bold text-blue-600 text-center mb-8">
        QUY TRÌNH SỬA CHỮA MÁY LẠNH
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <StepItem
          stepNumber={1}
          title="Tiếp nhận thông tin"
          description="Tiếp nhận thông tin và yêu cầu từ khách hàng."
          icon={<Image imgSrc={handlerTwo} />}
        />
        <StepItem
          stepNumber={2}
          title="Phân công thợ sửa"
          description="Ngay lập tức chúng tôi sẽ phân công thợ sửa đến gặp khách hàng và sắp xếp thời gian thích hợp."
          icon={<Image imgSrc={handlerThree} />}
        />
        <StepItem
          stepNumber={3}
          title="Kiểm tra máy"
          description="Nhân viên kiểm tra và báo cáo tình trạng, đưa ra giải pháp sửa chữa."
          icon={<Image imgSrc={handlerFour} />}
        />
        <StepItem
          stepNumber={4}
          title="Nhận báo giá"
          description="Nhận báo giá cụ thể từ Cơ Điện Lạnh Trần Khang cho dịch vụ sửa chữa hoặc thay thế linh kiện."
          icon={<Image imgSrc={handlerFive} />}

        />
        <StepItem
          stepNumber={5}
          title="Tiến hành sửa chữa"
          description="Sau khi khách hàng đồng ý, thợ sẽ tiến hành sửa chữa theo tình trạng và giải pháp đã thống nhất."
          icon={<Image imgSrc={handlerOne} />}

        />
        <StepItem
          stepNumber={6}
          title="Vận hành thử"
          description="Vận hành thử, khách hàng kiểm tra và thanh toán."
          icon={<Image imgSrc={handlerSix} />}

        />
      </div>
    </div>
  );
};




//
const ReasonItem = ({ icon, title, description }) => {
  return (
    <div className="flex items-start mb-4">
      <div className="text-red-500 mr-3">{icon}</div>
      <div>
        <h3 className="text-lg font-semibold">{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

const WarrantyPolicy = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 py-8">
      <div>
        <h2 className="text-2xl font-bold text-blue-600 mb-4">
          VÌ SAO CHỌN CHÚNG TÔI
        </h2>
        <ReasonItem
          icon={<i className="fas fa-users"></i>}
          title="Kinh Nghiệm"
          description="Đội ngũ kỹ thuật với nhiều năm kinh nghiệm. Đội tối thiểu 2 người để xử lý vấn đề."
        />
        <ReasonItem
          icon={<i className="fas fa-shipping-fast"></i>}
          title="Nhanh Chóng"
          description="Có mặt nhanh chóng sau 20 – 40 phút. Xử lý vấn đề nhanh chóng chính hãng!"
        />
        <ReasonItem
          icon={<i className="fas fa-dollar-sign"></i>}
          title="Không Phát Sinh Chi Phí"
          description="Báo giá trước khi làm việc. Không phát sinh thêm chi phí ngoài khác."
        />
      </div>
      <div>
        <h2 className="text-2xl font-bold text-blue-600 mb-4">
          CHÍNH SÁCH – BẢO HÀNH
        </h2>
        <p className="font-semibold mb-4">
          Cơ điện lạnh Trần Khang với đội ngũ nhân viên kỹ thuật nhiều năm kinh
          nghiệm, cung cấp và đáp ứng cho quý khách hàng dịch vụ sửa máy lạnh công nghiệp, dân dụng, VRV nhanh chóng, chất lượng với giá cả phải chăng.
        </p>
        <ul className="list-disc list-inside space-y-2">
          <li>Nhận cuộc gọi 24/7, kể cả ngày nghỉ và cuối tuần</li>
          <li>Có mặt sau khi nhận cuộc gọi của khách hàng nhanh chóng</li>
          <li>Kiểm tra và báo cho khách hàng tình trạng máy trước khi sửa chữa</li>
          <li>Nhận sửa chữa và bảo hành máy cho khách hàng</li>
        </ul>
      </div>
    </div>
  );
};

