


import React, { useEffect, useState } from "react";
import { useLocation, NavLink } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";

const Contact = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  useEffect(() => {
    setPrevLocation(location.state.data);
  }, [location]);

  const [clientName, setclientName] = useState("");
  const [email, setEmail] = useState("");
  const [messages, setMessages] = useState("");

  // ========== Error Messages Start here ============
  const [errClientName, setErrClientName] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [errMessages, setErrMessages] = useState("");
  // ========== Error Messages End here ==============
  const [successMsg, setSuccessMsg] = useState("");

  const handleName = (e) => {
    setclientName(e.target.value);
    setErrClientName("");
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrEmail("");
  };
  const handleMessages = (e) => {
    setMessages(e.target.value);
    setErrMessages("");
  };

  // ================= Email Validation start here =============
  const EmailValidation = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
  };
  // ================= Email Validation End here ===============

  const handlePost = (e) => {
    e.preventDefault();
    if (!clientName) {
      setErrClientName("Enter your Name");
    }
    if (!email) {
      setErrEmail("Enter your Email");
    } else {
      if (!EmailValidation(email)) {
        setErrEmail("Enter a Valid Email");
      }
    }
    if (!messages) {
      setErrMessages("Enter your Messages");
    }
    if (clientName && email && EmailValidation(email) && messages) {
      setSuccessMsg(
        `Thank you dear ${clientName}, Your messages has been received successfully. Futher details will sent to you by your email at ${email}.`
      );
    }
  };

  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="Dịch vụ" prevLocation={prevLocation} />
      
    <div className="p-6 bg-gray-100">
      {/* Tiêu đề */}
      <h1 className="text-3xl font-bold text-center text-blue-600 mb-6">
        Các Dịch Vụ Của Chúng Tôi
      </h1>

      {/* Danh sách dịch vụ */}
      <section className="space-y-6">
      <NavLink
                      to={"/baotrimaylanh"}
                      state={{ data: location.pathname.split("/")[1] }}
                    >
                      <div className="service-item bg-white p-4 shadow-md rounded-lg hover:shadow-lg transition">
          <h2 className="text-xl font-semibold text-gray-700 mb-2">Bảo trì máy lạnh</h2>
          <p className="text-gray-600">
            Chúng tôi cung cấp dịch vụ bảo trì máy lạnh định kỳ, giúp máy lạnh hoạt động hiệu quả và bền bỉ hơn.
          </p>
        </div>
                    </NavLink>
        <br/>

                    <NavLink
                      to={"/lapdatmaylanh"}
                      state={{ data: location.pathname.split("/")[1] }}
                    >
                      <div className="service-item bg-white p-4 shadow-md rounded-lg hover:shadow-lg transition">
          <h2 className="text-xl font-semibold text-gray-700 mb-2">Lắp đặt máy lạnh</h2>
          <p className="text-gray-600">
            Dịch vụ lắp đặt máy lạnh chuyên nghiệp cho các không gian nhà ở, văn phòng, khách sạn với các sản phẩm chính hãng.
          </p>
        </div>
                    </NavLink>
                    <br/>
                    <NavLink
                      to={"/vesinhmaylanh"}
                      state={{ data: location.pathname.split("/")[1] }}
                    >
                     <div className="service-item bg-white p-4 shadow-md rounded-lg hover:shadow-lg transition">
          <h2 className="text-xl font-semibold text-gray-700 mb-2">Vệ sinh máy lạnh</h2>
          <p className="text-gray-600">
            Vệ sinh máy lạnh sạch sẽ, giúp tăng hiệu suất làm lạnh và kéo dài tuổi thọ cho máy lạnh.
          </p>
        </div>
                    </NavLink>
                    <br/>
                    <NavLink
                      to={"/baotrimaylanh"}
                      state={{ data: location.pathname.split("/")[1] }}
                    >
                     <div className="service-item bg-white p-4 shadow-md rounded-lg hover:shadow-lg transition">
          <h2 className="text-xl font-semibold text-gray-700 mb-2">
            Bảo trì máy lạnh tại nhà
          </h2>
          <p className="text-gray-600">
            Chúng tôi cung cấp dịch vụ bảo trì máy lạnh tại nhà, thuận tiện và tiết kiệm thời gian cho khách hàng.
          </p>
        </div>
                    </NavLink>
                    <br/>
                    <NavLink
                      to={"/vesinhmaygiat"}
                      state={{ data: location.pathname.split("/")[1] }}
                    >
                      <div className="service-item bg-white p-4 shadow-md rounded-lg hover:shadow-lg transition">
          <h2 className="text-xl font-semibold text-gray-700 mb-2">Vệ sinh máy giặt</h2>
          <p className="text-gray-600">
            Dịch vụ vệ sinh máy giặt giúp loại bỏ cặn bẩn, mùi hôi và đảm bảo máy giặt hoạt động hiệu quả hơn.
          </p>
        </div>
                    </NavLink>
        

        

        

        
      </section>
      
    </div>
    </div>
  );
};

export default Contact;
